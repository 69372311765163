import React, { useRef } from "react"
import Select, { components } from "react-select"
import ArrowDropdown from "images/icons/dropdown-arrow.svg"

const selectStyles = {
  valueContainer: (base) => ({
    ...base,
    minHeight: "45px",
    height: "45px",
    width: "210px",
    padding: "0 15px",
    fontSize: "15px",
  }),
  control: (base, state) => ({
    ...base,
    // outline: state.isFocused ? "2px solid #4462ff" : "none",
    border: "solid #b6cfd0 1px",
    borderRadius: "8px",
    cursor: 'pointer',
    transition: 'all 0.25s',
    "&:hover": { borderColor: "#041E42", boxShadow: "0 0 0 1px #041E42" },
    boxShadow: state.isFocused ? "0 0 0 1px #041E42" : "",
    borderColor: state.isFocused ? "#041E42 !important" : "",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#47507e",
  }),
  placeholder: (base) => ({ ...base, color: "#718E8F", opacity: "0.5" }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: "0",
    paddingRight: "12px",
    svg: { width: "9px", height: "6px" },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all 0.25s',
    transform: state.isFocused ? 'rotate(180deg)' : ''
  }),
  menu: (base) => ({
    ...base,
    margin: "10px 0",
    borderRadius: "8px",
    overflow: "hidden",
    border: "solid #b6cfd0 1px",
    // boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
    boxShadow: "0 2px 25px rgba(71, 80, 126, 0.25)",
  }),
  menuList: (base) => ({ padding: "0" }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
    paddingLeft: "15px",
    paddingRight: "15px",
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#041E42', color: '#fff' },
    backgroundColor: state.isSelected
      ? "#041E42"
      : state.isFocused
      ? "#f1f2f7"
      : "white",
  }),
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropdown />
      </components.DropdownIndicator>
    )
  )
}

const ArticleSelect = ({ options, value, onChange }) => {
  const selectRef = useRef()
  const _onChange = e => {
    onChange(e)
    if (typeof selectRef.current?.select?.blur === 'function') {
      selectRef.current.select.blur()
    }
  }
  return (
    <Select
      ref={selectRef}
      options={options}
      value={value}
      onChange={_onChange}
      styles={selectStyles}
      components={{ DropdownIndicator }}
      isSearchable={false}
      placeholder="Select tier tag"
      openMenuOnFocus={true}
    />
  )
}

export default ArticleSelect
